import React, {useEffect, useRef, useState} from "react"
// import { ReactMic } from 'react-mic';
import { VoiceRecorder, VoiceRecorderPlugin, RecordingData, GenericResponse, CurrentRecordingStatus } from 'capacitor-voice-recorder';
import LinearProgress from '@mui/material/LinearProgress';

import {
    Container,
    Box,
    IconButton,
    Typography,
    Button,
    Grid
} from "@mui/material"

import PauseIcon from '@mui/icons-material/Pause';
import CloseIcon from '@mui/icons-material/Close';
import { ArrowBack, Close } from '@mui/icons-material';
import DangerousIcon from '@mui/icons-material/Dangerous';
import MicIcon from '@mui/icons-material/Mic';
import Popup from "../custom/modal/PopUp";

import { PrimaryActionButton } from "./Custom/Button";
import { THREAD, PROFILE } from "../../apiEndpoints";
import Alert from "./Custom/Alert";
import theme from "../../styles/theme";
import { InstructionAlert } from "../custom/alert";
import StreamingTTSComponent from './StreamingTTSComponent';
import { useNavigate } from 'react-router-dom';
import AudioBuffer from "./AudioStream";
import { TextField } from '../custom/field'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChatIcon from '@mui/icons-material/Chat';

export default function MobileControls({user, setUser, token, removeToken, conversation, setConversation, selectedAssistant, setState, isTextOnly, setTimeData, allowTextInput}) {
    const [isMobConversationStarted, setIsMobConversationStarted] = useState(false);
    const [isRecordingMob, setIsRecordingMob] = useState(false);
    const [loadingMob, setLoadingMob] = useState(false);
    const [agentTalking, setAgentTalking] = useState(false);
    const [stream, setStream] = useState(null);
    const messagesEndRef = useRef(null)
    const audio = useRef(null);
    const [keyPermission, setKeyPermission] = useState('audio-permission');
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      setOpen(false);
      stopConversation();
    };

    const [showInstructionsMobile, setShowInstructionsMobile] = useState('true');
    useEffect(() => {
      const userPreference = localStorage.getItem('showInstructions');
    
      if (userPreference === 'false') {
        setShowInstructionsMobile('false');
        localStorage.setItem('showInstructions', 'false')
      } else {
        localStorage.setItem('showInstructions', 'true')
      }
      scrollToBottom()
    }, [showInstructionsMobile]);


    const handleFileChange = (event) => {
      setFile(event.target.files[0]);
    };
  
    const handleSendMessage = () => {
        const newMessage = { text: message, file: file ? file.name : null };
        setChatMessages([...chatMessages, newMessage]);
        setMessage('');
        setFile(null); // reset the file after sending
    };
    
    const [alertMob, setAlertMob] = useState({
        open: false,
        message: "",
        title: "",
    });
    const threadRefMob = useRef(null);

    
    const isTextOnlyMobRef = useRef(isTextOnly)
    isTextOnlyMobRef.current = isTextOnly
    const isCoversationStartedMobRef = useRef(isMobConversationStarted)
    isCoversationStartedMobRef.current = isMobConversationStarted

    const [instructionMobileAlert, setInstructionMobileAlert] = useState({
      open: false,
      message: "",
      title: "",
    });
    const instructionAlertMessage = `
    • Start the Conversation: Click the 'Ask' button when you're ready to ask your question. <br/>
    • Wait for the Response: Click the 'Answer' button once you're done speaking to let the assistant respond.<br/>
    • Engage in Dialogue: Keep your questions brief to maintain a conversational flow, enhancing the overall experience.<br/>
    • Pause if Needed: You can stop the AI Agent at any time during the response.<br/>
    • End the Session: Click the 'Return' button when you are finished with your conversation. This will take you back to the main screen.`
    

    let recordedBlobs = [];
    let silentBlobSize = 969
    let silentCount = 0;
    let thresholdSilentCount = 40;
    let maxMinRangeValue = 20;

    const onStartMob = () => {
      navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then((stream) => {
        setIsRecordingMob(true);
      });
    };

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    };
  
    const streamAPIResponse = (response, totalStartTime) => {
      console.log(response)
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      const audioBuffer = new AudioBuffer(audio)

      let botResponseChunk = ""
      let finalOutput = {}
      let userQuestionText = ""
      let error = false
      let voiceId = null

      const processStream = ({ done, value }) => {
        if (done) {
          console.log('Stream complete');
          setStream(false)
          if (error) return

          // threadRefMob.current = response.data.data.thread_id
          threadRefMob.current = finalOutput['thread_id']
          console.log(finalOutput)

          if (!finalOutput['response']) {
            setAlertMob({
              title: "Info",
              message: "Coudn't process your query! Try again!!",
              open: true
            })
            setIsMobConversationStarted(false)
            setIsRecordingMob(false)
            setLoadingMob(false)
            setAgentTalking(false)
            return
          }
          conversation[conversation.length - 1]["response"] = JSON.parse(JSON.stringify(finalOutput['response']))
          setConversation(JSON.parse(JSON.stringify(conversation)))

          setUser(finalOutput.user_data);
          
          if (isTextOnly) {
            setIsMobConversationStarted(false)
            setIsRecordingMob(false)
            setLoadingMob(false)
            const total_elapsed_time = performance.now() - totalStartTime;
            setTimeData({
              sst_elapsed_time: finalOutput.sst_elapsed_time,
              assistant_elapsed_time: finalOutput.assistant_elapsed_time,
              elevenlabs_elapsed_time: 0,
              totalTime: parseFloat(total_elapsed_time / 1000).toFixed(2)
            })
          } else {
            playResponseAndUpdateConversationMob(finalOutput.response, finalOutput.conversation, finalOutput.voice_id,finalOutput.sst_elapsed_time, finalOutput.assistant_elapsed_time, totalStartTime, finalOutput.optimize_streaming_latency)
          }
          return;
        }
  
        // Decode and log the chunk
        var chunk = ""
        try {
          chunk = JSON.parse(decoder.decode(value, { stream: true }))
        } catch(e) {
          chunk = decoder.decode(value, { stream: true })
        }
        console.log('Received chunk:', JSON.stringify(chunk));

        if (chunk?.startsWith && chunk?.startsWith('user_q: ')) {
          setStream(true)
          // first set the user question and separate the user object
          const q = chunk.split(',user_d: ')
          const firstHalf = q[0].split(',voiceId: ')
          userQuestionText = firstHalf[0].replace('user_q: ', '')
          // audioBuffer.setup(firstHalf[1])

          // add the question in converation bot
          console.log("current conv ->", conversation)
          const new_conv = conversation || []
          new_conv.push({
            "role": "user",
            "response": userQuestionText
          })
          new_conv.push({
            "role": "assistant",
            "response": ""
          })
          setConversation(new_conv)

          // now work on the user
          console.log(q)
          setUser(JSON.parse(q[1]))
          try {
            chunk = q[1].split("}")[1]
          } catch(e) {
            console.log(e)
          }
          scrollToBottom()
        }

        if (chunk?.['thread_id']) {
          // final response received
          finalOutput = chunk
        } else if (chunk?.message?.includes("Message content must be non-empty"))  {
          setAlertMob({
            title: "Info",
            message: "Coudn't catch your audio! Can you ask again ?",
            open: true
          })
          error = true
          closeConversation()
        } else if (chunk?.startsWith && chunk?.startsWith('async_r: ')){

          // check if final output exist in the chunk
          if (chunk.includes('{"thread_id": "')) {
            const splitChunk = chunk.split('{"thread_id": "')
            finalOutput = JSON.parse('{"thread": "' + splitChunk[1])
            chunk = splitChunk[0]
          }
          function checkAndReplaceInArrayStartsWith(chunk) {
            // here every item is added as per priority and flow
            const responseFilter = ['async_r: :', 'async_r: Response:', 'async_r: Response', 'Response:', 'Response', 'async_r: ', 'async_r:']
            chunk = chunk.trim()
            responseFilter.map(replaceElem => {
              if (chunk.includes(replaceElem)) {
                chunk = chunk.replaceAll(replaceElem, '');
                return true
              }
              return false
            })
            return chunk
          }
          const responseToAppend = checkAndReplaceInArrayStartsWith(chunk)
          botResponseChunk += responseToAppend

          // audioBuffer.addToBuffer(responseToAppend)

          conversation[conversation.length - 1]["response"] = JSON.parse(JSON.stringify(botResponseChunk))
          console.log(conversation)
          setConversation(JSON.parse(JSON.stringify(conversation)))
          scrollToBottom()
        } else if (chunk?.token_expire?.toString() === 'true') {
          setAlertMob({
            title: "Token expired",
            message: "Your token has expired and need relogin.",
            open: true
          })
          navigate("/auth")
        } else  {
          console.log("Unknown chunk -> ", chunk)
        }
  
        // Read the next chunk
        reader.read().then(processStream);
      };
  
      // Start reading the stream
      reader.read().then(processStream);
    }

    const onStopMob = (recordedBlob, selectedAssistant, threadId, useText=false) => {
      if (!isCoversationStartedMobRef.current && !useText) return
      
      setLoadingMob(true)
      const formData = new FormData();

      if (useText) {
        formData.append('text', recordedBlob);
      } else {
        const audioBlob = recordedBlob
        formData.append('audio', audioBlob, 'voice_input.mp3');
      }
      console.log(formData)
      

      const totalStartTime = performance.now();

      if (threadId){

        let additionalData = {
          assistant_id: selectedAssistant.id,
          thread_id: threadId
        }

        Object.keys(additionalData).forEach((key) => {
          formData.append(key, additionalData[key]);
        });

        if (file) {
          formData.append("file", file)
        }
  
        fetch(THREAD + threadId + "/", {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formData
        })
        .then(response => {
          streamAPIResponse(response, totalStartTime)
          setMessage('')
        })
        .catch(error => {
            let message
            let token_expired 
  
            try{
                message = error.response.data.message
            }catch(e){
                message = error.message
            }
  
            try{
              token_expired = error.response.data.token_expired
            }catch(e){
              token_expired = false
            }
  
            if (token_expired){
              removeToken()
            }
  
            
            if (message.includes('Message content must be non-empty')) {
              setAlertMob({
                title: "Info",
                message: "Coudn't catch your audio! Can you ask again ?",
                open: true
              })
            } else {
              setAlertMob({
                title: "Error",
                message: message,
                open: true
              })
            }
  
            setIsRecordingMob(false);
            setLoadingMob(false)
                
        })

      }
      else{

        let additionalData = {
          assistant_id: JSON.stringify(selectedAssistant.id)
        }
        Object.keys(additionalData).forEach((key) => {
          formData.append(key, additionalData[key]);
        });
        
        if (file) {
          formData.append("file", file)
        }
        
        fetch(THREAD, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formData
        })
        .then((response) => {
          streamAPIResponse(response, totalStartTime)
          setMessage('')
        })
        .catch(error => {
            let message
            let token_expired 
  
            try{
                message = error.response.data.message
            }catch(e){
                message = error.message
            }
  
            try{
              token_expired = error.response.data.token_expired
            }catch(e){
              token_expired = false
            }
  
            if (token_expired){
              removeToken()
            }
  
            setAlertMob({
                title: "Error",
                message: message,
                open: true
            })
  
            setIsRecordingMob(false);
            setLoadingMob(false)
            setIsMobConversationStarted(false)
                
        })
      }

    };
  
    const onDataMob = (recordedBlob) => {
  
      // Check for silence
      if (isSilent(recordedBlob)) {
          recordedBlobs = [];
          silentCount = 0;
          setIsRecordingMob(false);
          
      }
    };
  
    const isSilent = (recordedBlob) => {
      let blobSize = recordedBlob.size
      
      if ((((silentBlobSize - maxMinRangeValue) <= recordedBlobs[recordedBlobs.length - 1]) && (recordedBlobs[recordedBlobs.length - 1] <= (silentBlobSize + maxMinRangeValue))) && (((silentBlobSize - maxMinRangeValue) <= blobSize) && (blobSize <= (silentBlobSize + maxMinRangeValue)))){
        silentCount += 1
      }
      else{
        silentCount = 0
      }
      
      recordedBlobs.push(blobSize)
  
      return silentCount === thresholdSilentCount
  
    };


    const playResponseAndUpdateConversationMob = async (textData, conversationData, voiceId, sst_elapsed_time, assistant_elapsed_time, totalStartTime, optimize_streaming_latency) => {
      console.log(sst_elapsed_time, assistant_elapsed_time, totalStartTime, "-----")
      if (isTextOnlyMobRef.current){

        setIsRecordingMob(false);
        setLoadingMob(false);
        // setConversation(conversationData);
        const total_elapsed_time = performance.now() - totalStartTime;

        setTimeData({
          sst_elapsed_time: sst_elapsed_time,
          assistant_elapsed_time: assistant_elapsed_time,
          elevenlabs_elapsed_time: null,
          totalTime: parseFloat(total_elapsed_time / 1000).toFixed(2)
        })

        return false;

      }
      else{
        const startTime = performance.now();
        // const url = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}?optimize_streaming_latency=${optimize_streaming_latency}`;
        const url = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`;
        const api_key = process.env.REACT_APP_ELAB_API_KEY
    
        const headers = {
          Accept: 'audio/mpeg',
          'xi-api-key': api_key,
          'Content-Type': 'application/json',
        };

        // remove file references
        textData = textData.replace(/【.*?】/g, '');

        const data = {
          text: textData,
          model_id: 'eleven_flash_v2_5',//'eleven_multilingual_v2', //'eleven_turbo_v2'
          voice_settings: { stability: 0.6, similarity_boost: 0.85 },
        };
        console.log("elevenlabs")
        console.log(textData)

    
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
          });
    
          if (response.ok) {

            const reader = response.body.getReader();
            const audioChunks = [];
            let receivedLength = 0;
            console.log("response received!", response)

            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                  console.log("don")
                  break;
                };
                audioChunks.push(value);
                receivedLength += value.length;
            }
            const audioBlob = new Blob(audioChunks, { type: 'audio/mpeg' });
            // const audioBlob = await response.blob();
            const audioUrl = URL.createObjectURL(audioBlob);
            audio.current.src = audioUrl;
            const playPromise = new Promise((resolve) => {
              audio.current.onended = resolve;
            });
            audio.current.onended = (event) => {
              console.log(
                "audio speak over",
              );
              setIsMobConversationStarted(false)
              setIsRecordingMob(false)
              setLoadingMob(false)
              setAgentTalking(false)
            };
            // Concatenate the chunks into a single ArrayBuffer
            // const arrayBuffer = new Uint8Array(receivedLength);
            // let position = 0;
            // for (let chunk of audioChunks) {
            //   arrayBuffer.set(chunk, position);
            //   position += chunk.length;
            // }

            // Create an AudioContext
            // const audioContext = new (window.AudioContext || window.webkitAudioContext)();

            // Decode the audio data
            // const audioBuffer = await audioContext.decodeAudioData(arrayBuffer.buffer);

            // Create a buffer source
            // const source = audioContext.createBufferSource();
            // source.buffer = audioBuffer;

            // Connect the source to the audio context's destination (the speakers)
            // source.connect(audioContext.destination);

            // Optional: Add an event listener to handle when the audio finishes playing
            // source.onended = () => {
            //   console.log('Audio playback finished');
            //   closeConversation()
            //   setAgentTalking(false)
            //   setIsMobConversationStarted(false)
            // };

            setIsRecordingMob(false);
            setLoadingMob(false);
            // setConversation(conversationData);
            const elevenlabs_elapsed_time = performance.now() - startTime;
            const total_elapsed_time = performance.now() - totalStartTime;

            setTimeData({
              sst_elapsed_time: sst_elapsed_time,
              assistant_elapsed_time: assistant_elapsed_time,
              elevenlabs_elapsed_time: parseFloat(elevenlabs_elapsed_time / 1000).toFixed(2),
              totalTime: parseFloat(total_elapsed_time / 1000).toFixed(2)
            })
            setAgentTalking(true)
            audio.current.play().catch(error => {
              setAgentTalking(false)
              setAlertMob({
                title: "Error",
                message: 'Audio playback failed',
                open: true
              })
                console.error('Audio playback failed:', error);
            });
            await playPromise;
            // Start playing the audio
            // source.start(0);
          } else {
            setAlertMob({
              title: "Error",
              message: 'Text to speech conversion failed',
              open: true
            })
            console.error('Text to speech conversion failed');
          }
        } catch (error) {
          console.error('Error:', error);
        } finally {
          // new cap code below added
          // closeConversation()
        }
      }
    };

    const closeConversation = () => {
      setIsMobConversationStarted(false)
      setIsRecordingMob(false)
      setLoadingMob(false)
    }

    const handleAlertClose = () => {
      setAlertMob({
        open: false,
        title: "",
        message: ""
      });
     };

         
    const handleInstructionMobileAlertClose = () => {
      setInstructionMobileAlert({
        open: false,
        title: "",
        message: ""
      });
     };

    const tokenActive = async () => {
      const res = await fetch(PROFILE, {
        method: 'get',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      return res.status === 200
    }
    
    const startConversation = async () => {
      // validate if token active
      if (!await tokenActive()) {
        setAlertMob({
          open: true,
          title: "Session expired!",
          message: "You need to login again."
        })
        navigate("/auth")
        return
      }
      if (user?.token_balance <= 0){
        setAlertMob({
          open: true,
          title: "Error",
          message: "You dont have enough tokens to start a conversation, please subscribe to one of our plans."
        })
        return
      }
      if (localStorage.getItem('showInstructions') === 'true') {
        setInstructionMobileAlert({
          title: "Information",
          message: instructionAlertMessage,
          open: true
        })
        return
      }

      if (message.length > 0) {
        // chat enabled
        setIsMobConversationStarted(true)
        onStopMob(message, selectedAssistant, threadRefMob.current, true)
        return
      }
        // capacitor voice recorder new code
        if (localStorage.getItem(keyPermission) === 'true') {
          startRecordingAudio()
        } else {
          const granted = await requestAudioPermission();
          if (granted) {
            startRecordingAudio()
          } else {
            setAlertMob({
              open: true,
              title: "No Permission",
              message: "Audio permission not set!"
            })
            stopConversation();
          }
        }
      };

      const requestAudioPermission = async () => {
        const permission = await VoiceRecorder.requestAudioRecordingPermission()
            if (permission.value) {
            } else {
              setAlertMob({
                open: true,
                title: "No Permission",
                message: "Audio permission not set!"
              })
              stopConversation();
            }
        localStorage.setItem(keyPermission, permission.value.toString())
        return permission.value
        };

      const startRecordingAudio = () => {
        setIsMobConversationStarted(true)  
        setIsRecordingMob(true)
        VoiceRecorder.startRecording()
          .then((result) => {
            handleOpen()
            console.log(result.value)
          })
          .catch(async (error) => {
            console.log(error)
            if (error.toString().includes("MISSING_PERMISSION") || error.toString().includes("FAILED_TO_RECORD")) {
              setAlertMob({
                open: true,
                title: "Audio permission missing!",
                message: "Permission have been not given yet."
              })
              if (await requestAudioPermission()) {
                startRecordingAudio()
              }
            } else if (error.toString().includes("CANNOT_VOICE_RECORD_ON_THIS_PHONE")) {
              setAlertMob({
                open: true,
                title: "Recording not supported!",
                message: "This device doesn't support audio recording."
              })
              closeConversation()
            } else if (error.toString().includes("MICROPHONE_BEING_USED")) {
              setAlertMob({
                open: true,
                title: "Microphone being used",
                message: "This device mic is being used by another application."
              })
              closeConversation()
            } else {
              setAlertMob({
                open: true,
                title: "Unknown error in recording",
                message: "Something went wrong! Please reach out to our support!"
              })
              closeConversation()
            }
          })
      }
      
      const handleOnContinueMobileClick = (dontShowAgain=false) => {
        setShowInstructionsMobile(!dontShowAgain);
        // localStorage.setItem('showInstructions', !dontShowAgain.toString());
        localStorage.setItem('showInstructions', 'false');
        handleInstructionMobileAlertClose()

        startConversation()
        // setIsMobConversationStarted(true)  
        // setIsRecordingMob(true)
        };
      
        const handleMobPause = () => {          
          setIsMobConversationStarted(false)
          setIsRecordingMob(false)
          setLoadingMob(false)
          
        };

      function base64ToBlob(base64String, contentType = '') {
          const byteCharacters = atob(base64String);
          const byteArrays = [];
      
          for (let i = 0; i < byteCharacters.length; i++) {
              byteArrays.push(byteCharacters.charCodeAt(i));
          }
      
          const byteArray = new Uint8Array(byteArrays);
          // console.log(byteArray)
          return new Blob([byteArray], { type: contentType });
      }

      function stopConversation() {
        if (audio.current && !audio.current.ended && audio.current.currentTime > 0) {
          console.log(audio)
          audio.current.pause();
          audio.current.currentTime = 0;
          setIsMobConversationStarted(false)
          setIsRecordingMob(false)
          setLoadingMob(false)
          setAgentTalking(false)
          return
        }
        setIsRecordingMob(false)
        setLoadingMob(false)
        try {
          VoiceRecorder.stopRecording()
          .then((result) => {
            // console.log(result.value)
            const base64Sound = result.value.recordDataBase64 // from plugin
            const mimeType = result.value.mimeType  // from plugin
            // console.log(mimeType)

            // play the audio from the recorded file
            // const audioRef = new Audio(`data:${mimeType};base64,${base64Sound}`)
            // audioRef.oncanplaythrough = () => audioRef.play()
            // audioRef.load();

            const blob = base64ToBlob(base64Sound, mimeType)
            // console.log(blob)
            onStopMob(blob, selectedAssistant, threadRefMob.current)
          })
          .catch(error => {
            // setIsMobConversationStarted(false)
            setAgentTalking(false)
          })
        } catch(e) {
          console.log(e)
          setAlertMob({
            title: "Error",
            message: "Recording fetch failed!",
            open: true
          })
          setIsRecordingMob(false);
          setLoadingMob(false)
        }
        
        // window.location.reload()
      }

      function handleSelectAssistant() {
        setState({
          choose: true
        })
      }

      const removeSelectedFile = (event) => {
        event.stopPropagation(); // Prevent parent click event from firing
        console.log('Child clicked');
        setFile(null);
      }

  return (
    <Container style={{ height: '100%', overflowY: 'auto' }}>
        <Alert alert={alertMob}   handleAlertClose={handleAlertClose} />
        <InstructionAlert alert={instructionMobileAlert} handleAlertClose={handleInstructionMobileAlertClose} onContinueClick={handleOnContinueMobileClick} />

        <Grid container spacing={2}>
          <Grid item xs={8}>
            {allowTextInput ?
                <TextField
                  fullWidth
                  variant="outlined"
                  value={message}
                  autoComplete="off"
                  name="input-text-message"
                  inputProps={{ autoComplete: 'new-password' }}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type your question..."
              /> : ''}
          </Grid>

          <Grid item xs={4}>
            <input 
              accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"  
              style={{ display: 'none' }}
              id="file-upload"
              type="file"
              onChange={handleFileChange}
            /> 
              {file ? <CloseIcon onClick={removeSelectedFile} /> : <label htmlFor="file-upload">
                <AttachFileIcon aria-label="upload file" /> Upload Doc and Ask
              </label>}

            <label htmlFor="file-upload">
              <IconButton color="primary" sx={{ color: 'white' }} aria-label="upload file" component="span">
                  {file && <div style={{fontSize: '12px'}}>{file.name}</div>}
              </IconButton>
            </label>
          </Grid>
        </Grid>
        <br/>
        {/* <Box display="flex" alignItems="center" margin={'4px'}>
            {allowTextInput ?
              <TextField
                fullWidth
                variant="outlined"
                value={message}
                autoComplete="off"
                name="input-text-message"
                inputProps={{ autoComplete: 'new-password' }}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your question..."
            /> : ''}
            

            <input 
              accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"  
              style={{ display: 'none' }}
              id="file-upload"
              type="file"
              onChange={handleFileChange}
            /> 
            <label htmlFor="file-upload">
              <IconButton color="primary" sx={{ color: 'white' }} aria-label="upload file" component="span">
                  <AttachFileIcon />
              </IconButton>
            </label>
        </Box> */}
        <Box >
           
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <PrimaryActionButton disabled={isMobConversationStarted}  onClick={() => startConversation()}>
                    {
                      isRecordingMob?
                      
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        Recording
                        <img src={require("../../assets/img/recording162.gif")} 
                        style={{
                          width: '24px', 
                          height: '24px',
                          marginLeft: '20px'
                        }} alt="Recording" />
                      </Box>:
                      loadingMob?

                      <Box sx={{ width: '100%', m: 1,  }}>
                        <LinearProgress sx={{ color: theme.PRIMARY_BUTTON_LOADER }} />
                      </Box>:
                      isMobConversationStarted?
                      "Speaking":
                      <div style={{display: "flex", alignItems: "center"}}>{message.length === 0 ? <MicIcon /> : <ChatIcon /> } Ask</div>
                    }
                </PrimaryActionButton>&nbsp;

                {/* <IconButton
                  onClick={stopConversation}
                  disabled={!isMobConversationStarted}
                > 
                  <DangerousIcon sx={{ color: isMobConversationStarted? theme.button.danger.bg: 'grey', fontSize: '2rem' }} />
                  {isMobConversationStarted ? <span style={{color: 'white', fontSize: '1rem'}}>Stop</span> : ""}
                </IconButton> */}
                {/* <PrimaryActionButton disabled={!isMobConversationStarted}  onClick={() => stopConversation()}> */}
                <PrimaryActionButton disabled={!agentTalking}  onClick={() => stopConversation()}>
                {/* <DangerousIcon sx={{ color: isMobConversationStarted? theme.button.danger.bg: 'grey', }} /> Answer */}
                {/* {agentTalking ? "Stop" : "Answer"} */}
                <CloseIcon /> Stop
                </PrimaryActionButton>&nbsp;
                <Popup open={open} handleClose={handleClose} />

                {/* <IconButton
                  onClick={handleMobPause}
                  disabled={!isMobConversationStarted}
                >
                  <PauseIcon sx={{ color: theme.button.primary.bg, fontSize: '2rem' }} />

                </IconButton> */}

                {/* <IconButton
                  onClick={handleSelectAssistant}
                  disabled={isMobConversationStarted}
                >
                  <ArrowBack sx={{ color: theme.button.primary.bg, fontSize: '2rem' }} />
                </IconButton> */}
                <PrimaryActionButton onClick={handleSelectAssistant}
                  disabled={isMobConversationStarted}>
                    <ArrowBack sx={{ color: 'black' }} /> 
                    Return
                </PrimaryActionButton>

                <p  />
                <audio id="audioPlayer" hidden controls ref={audio}/>
            </Box>
            <Typography sx={{mb:2, fontSize: {xs: 12, md: 12, margin: 0, padding:0}}} variant="p">Click the 'Ask' button to ask a question.</Typography>
        {/* <StreamingTTSComponent /> */}
        </Box>
        <div ref={messagesEndRef} />
    </Container>
    
)
}
