import { useState } from 'react'

import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { Box, Button, Checkbox, Divider, FormControlLabel, Modal, Radio, RadioGroup, Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { ActionButton, SubmitButton } from '../custom/button';
import { TextField } from '../custom/field';
import * as theme from '../../theme'
import logo from '../../logo.png';

import loginImage from '../../assets/img/login_image.png'

import { FORGOT_PASSWORD, SIGNIN, SIGNUP } from '../../apiEndpoints';
import { Alert } from '../custom/alert';

const defaultTheme = createTheme();

export default function AuthenticationBody({setToken, setUser, setLoading}) {
 const navigate = useNavigate()
 const [state, setState] = useState({
  signIn: true,
  signUp: false,
  signInAdmin: false,
  forgotPassword: false,
 });
 const [dislaimerOpen, setDisclaimerOpen] = useState(false);
 const [apiLoading, setAPILoading] = useState(false);
 const [signinFormValues, setSigninFormValues] = useState({})
 const [signupFormValues, setSignupFormValues] = useState({account_type: 'individual'})
 const [forgotPasswordFormValues, setForgotPasswordFormValues] = useState({})
 const [phoneNumber, setPhoneNumber] = useState("");
 const [alert, setAlert] = useState({
  open: false,
  title: "",
  message: ""
 });

 const handleAlertOpen = (title, message) => {
  setAlert({
    open: true,
    title: title,
    message: message
  });
 };

 const handleAlertClose = () => {
  setAlert({
    open: false,
    title: "",
    message: ""
  });
 };


 const handleSigninFormSubmit = (event) => {
  event.preventDefault()
  setAPILoading(true)
  axios.post(
      SIGNIN, 
      {
          "email": signinFormValues.email.toLowerCase(),
          "password": signinFormValues.password
      }, 
      {
          headers: { 'Content-Type': 'application/json' } 
      }
  )
  .then(response => response.data.data)
  .then(data => {
      setAPILoading(false)
      const accessToken = data.access_token
      const role = data.role
      const userData = data.user_data
      setToken(accessToken)
      setUser(userData)
      navigate("/assistant")  
      setLoading({
        state: true,
        headline: role==="user" ? "Welcome to AI Assistant Expert Platform" : "Welcome to AI Assistant Expert Adminstration Platform",
        paragraph: ""
      }
      )
      setTimeout(() =>{
        setLoading({state:false, headline: "", paragraph: ""})
      }, 1500)
        

  })
  .catch(error => {
    setAPILoading(false)
    const errorResponse = error.response
    handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
  });

 }

  const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    const cleaned = value.replace(/\D/g, "");

    // Format according to US phone number style (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{1,3})(\d{1,3})?(\d{1,4})?$/);

    if (!match) return cleaned;

    const [, areaCode, prefix, line] = match;
    let formattedNumber = "";

    if (areaCode) {
      formattedNumber = `(${areaCode}`;
      if (areaCode.length === 3) formattedNumber += ") ";
    }
    if (prefix) {
      formattedNumber += prefix;
      if (prefix.length === 3) formattedNumber += "-";
    }
    if (line) {
      formattedNumber += line;
    }

    return formattedNumber;
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    const formatted = formatPhoneNumber(input);
    signinFormValues.phone = input
    setSigninFormValues(signinFormValues)
    setPhoneNumber(input);
    handleSignupFormChange(e)
  };

 const handleSignupFormSubmit = (event) => {
  event.preventDefault()
  setAPILoading(true)
  axios.post(
      SIGNUP, 
      {
          "first_name": signupFormValues.first_name,
          "last_name": signupFormValues.last_name,
          "business_name": signupFormValues.business_name,
          "email": signupFormValues.email.toLowerCase(),
          "password": signupFormValues.password,
          "phone": signinFormValues.phone,
          "account_type": signupFormValues.account_type,
      }, 
      {
          headers: { 'Content-Type': 'application/json' } 
      }
  )
  .then(response => response.data)
  .then(data => {
    setAPILoading(false)
      handleAlertOpen("Success", data.message)   
      setSignupFormValues({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
      })
      handleState({signIn:true})
  })
  .catch(error => {
    setAPILoading(false)
    const errorResponse = error.response
    handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)

  });

 }

 const handleForgotPasswordFormSubmit = (event) => {
  event.preventDefault()
  setAPILoading(true)
  axios.post(
      FORGOT_PASSWORD, 
      {
          "email": forgotPasswordFormValues.email,
      }, 
      {
          headers: { 'Content-Type': 'application/json' } 
      }
  )
  .then(response => response.data)
  .then(data => {
    setAPILoading(false)
      handleAlertOpen("Success", data.message)   
      setForgotPasswordFormValues({
        email: '',
        
      })
  })
  .catch(error => {
    setAPILoading(false)
    const errorResponse = error.response
    handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)

  });

 }
 
 const handleSigninFormChange = (event) => {
  setSigninFormValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
  }))
}

const handleSignupFormChange = (event) => {
  setSignupFormValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
  }))
}

const handleForgotPasswordFormChange = (event) => {
  setForgotPasswordFormValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
  }))
}

  const handleState = ({signIn, signUp, signInAdmin, forgotPassword}) => {
    setState({
      signIn: signIn,
      signUp: signUp,
      signInAdmin: signInAdmin,
      forgotPassword: forgotPassword
    })
  }
 
  return (
    <>
      <Alert alert={alert} handleAlertClose={handleAlertClose} />
      {/* Desktop + Mobile version */}
      <Box sx={{ display: { xs: 'block', md: 'block' } }}>
        <Box display="flex" justifyContent="center" alignItems="center"  >
          <Box padding={5} textAlign="center"  sx={{my:2}}>
            <ThemeProvider theme={defaultTheme}>

              <Container component="main"  sx={{
                display: state.signUp?"flex":"none",
                minHeight: '60vh',
               }}  id='signup' maxWidth="xs" >
                <CssBaseline />
                <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                    <img src={loginImage} alt="Logo" width={180} />
                  <hr/>
                  <Typography component="h1" variant="h4">
                    Sign up
                  </Typography>
                  <Box component="form"  onSubmit={handleSignupFormSubmit} sx={{ mt: 3, color:theme.BODY_FONT }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="first_name"
                          required
                          fullWidth
                          id="firstName"
                          label="First Name"
                          value={signupFormValues.first_name}
                          onChange={handleSignupFormChange} 
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="last_name"
                          label="Last Name"
                          name="last_name"
                          value={signupFormValues.last_name}
                          onChange={handleSignupFormChange} 
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          type="email"
                          autoComplete="username"
                          value={signupFormValues.email}
                          onChange={handleSignupFormChange} 
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {/* <MuiPhoneNumeber defaultCountry={'us'} /> */}
                        <TextField
                          fullWidth
                          id="phone"
                          label="Phone"
                          name="phone"
                          type="tel"
                          value={phoneNumber}
                          onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,14)
                          }}
                          onChange={handlePhoneChange} 
                        />
                      </Grid>
                      {
                      signupFormValues.account_type === 'business' &&
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="business_name"
                          label="Business Name"
                          name="business_name" 
                          type="text"
                          value={signupFormValues.business_name}
                          onChange={handleSignupFormChange} 
                        />
                      </Grid>
                      }
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                          value={signupFormValues.password}
                          onChange={handleSignupFormChange} 
                        />
                      </Grid>

                      <Grid item xs={12}>
                      
                      <RadioGroup
                        aria-label="account_type"
                        name="account_type"
                        value={signupFormValues.account_type}
                        onChange={handleSignupFormChange}
                        row
                        required
                      >
                        <FormControlLabel
                          value="individual"
                          control={<Radio sx={{
                            color:'#ffffff',
                            '&.Mui-checked': {
                            color: '#ffffff',
                            },
                        }} />}
                          label="Individual"
                        />
                        <FormControlLabel
                          value="business"
                          control={<Radio sx={{
                            color:'#ffffff',
                            '&.Mui-checked': {
                            color: '#ffffff',
                            },
                        }} />}
                          label="Business"
                        />
                      </RadioGroup>
                      
                      </Grid>


                      <Grid item xs={12}>
                        <FormControlLabel
                        required
                          control={<Checkbox sx={{
                            '&.Mui-checked': {
                              color: theme.PRIMARY_BUTTON,
                            },
                            color: theme.PRIMARY_BUTTON,
                          }}  />}
                          label={
                            <label>
                              I have read the <label style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setDisclaimerOpen(true)}>disclaimer</label> 
                          </label>
                          }
                        />
                      </Grid>
                    </Grid>
                    <SubmitButton
                      fullWidth
                      loading={apiLoading}
                      variant="contained"
                      buttonText={"Sign Up"}
                      sx={{my:2}}
                      
                    />
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button onClick={() => handleState({signIn:true})}  variant="body2">
                          Already have an account? Sign in
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Disclaimer Modal */}
                  <Modal
                    open={dislaimerOpen}
                    onClose={() => setDisclaimerOpen(false)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                  >
                    <Box sx={{
                      bgcolor: 'white',
                      padding: 4,
                      margin: 4,
                      maxHeight: '80vh',
                      overflowY: 'auto',
                    }}>
                      <Typography variant="h5" id="modal-title" gutterBottom>
                        Disclaimer
                      </Typography>

                      <Divider sx={{ backgroundColor: "#000000", mb:2, borderWidth: 2}} />
                      <Typography variant="body1" id="modal-description">
                      When using the AIAssistantExpert.com web app that provides AI Assistants for various professions using ChatGPT 4 for verbal conversations, please consider the following points for a comprehensive understanding of the service:
                      <br/>
                      <br/>
                      <strong> AI Capabilities </strong>: The AI assistant is powered by OpenAI's ChatGPT 4, offering conversational capabilities across various domains. However, its responses are generated based on patterns in data and not personal experience or human insight.
                      <br/>
                      <br/>
                      <strong>Data Privacy</strong>: Interactions with the AI might be stored or analyzed for improving the service. Ensure you understand the app's privacy policy and how your data is used and protected.
                      <br/>
                      <br/>
                      <strong>Accuracy and Reliability</strong>: While AI strives for accuracy, it may not always provide reliable or fact-checked information. Users should verify critical information independently.
                      <br/>
                      <br/>
                      <strong>Limitations in Understanding</strong>: AI may not fully understand context, nuance, or cultural specifics. It's essential to use clear and concise language to improve interaction quality.
                      <br/>
                      <br/>
                      <strong>Continuous Learning</strong>: The AI model may update continually to provide better responses. As such, the nature and quality of interactions might change over time.
                      <br/>
                      <br/>
                      <strong>Professional Advice</strong>: AI should not replace professional advice in critical areas like medical, legal, or financial decision-making. Always consult a qualified human professional for significant matters.
                      <br/>
                      <br/>
                      <strong>User Responsibility</strong>: Users are responsible for the queries they input and should avoid sharing sensitive personal information.
                      <br/>
                      <br/>
                      By using the service, you acknowledge the capabilities and limitations of AI technology and agree to use it appropriately and responsibly.                      
                      </Typography>
                      
                      <ActionButton sx={{mt:4}} onClick={() => setDisclaimerOpen(false)} buttonText={"Close"} />
                    </Box>
                  </Modal>
                </Box>
              </Container>
              
              <Container component="main"  sx={{
                display: state.signIn?"flex":"none",
                minHeight: '60vh',
                }} id='signin' maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                >
                  
                  <img src={loginImage} alt="Logo" width={180} />
                  <hr/>
                  <Typography component="h1" variant="h4">
                    Sign in
                  </Typography>
                  <Box component="form" onSubmit={handleSigninFormSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                      
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          type="email"
                          autoComplete="email"
                          value={signinFormValues.email}
                          onChange={handleSigninFormChange} 
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="new-password"
                          value={signinFormValues.password}
                          onChange={handleSigninFormChange} 
                        />
                      </Grid>
                    </Grid>
                    <SubmitButton
                      fullWidth
                      loading={apiLoading}
                      variant="contained"
                      buttonText={"Sign In"}
                      sx={{my:2}}
                      
                    />
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button onClick={() => handleState({forgotPassword:true})}  variant="body2">
                          Forgot Password?
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button onClick={() => handleState({signUp:true})}  variant="body2">
                          Don't have an account? Sign up
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>

              <Container component="main"  sx={{
                display: state.signInAdmin?"flex":"none",
                minHeight: '60vh',
                }} id='signin' maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                >
                  
                  <img src={logo} alt="Logo" width={180} />
                  <hr/>
                  <Typography component="h1" variant="h4">
                    Sign in as Admin
                  </Typography>
                  <Box component="form" onSubmit={handleSigninFormSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                      
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          type="email"
                          autoComplete="email"
                          value={signinFormValues.email}
                          onChange={handleSigninFormChange} 
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="new-password"
                          value={signinFormValues.password}
                          onChange={handleSigninFormChange} 
                        />
                      </Grid>
                    </Grid>
                    <SubmitButton
                      fullWidth
                      loading={apiLoading}
                      variant="contained"
                      buttonText={"Sign In As Admin"}
                      sx={{my:2}}
                      
                    />
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button onClick={() => handleState({signIn:true})}  variant="body2">
                          Sign in as User
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>


              <Container component="main"  sx={{
                display: state.forgotPassword?"flex":"none",
                minHeight: '60vh',
               }}  id='signup' maxWidth="xs" >
                <CssBaseline />
                <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                    <img src={logo} alt="Logo" width={180} />
                  <hr/>
                  <Typography component="h1" variant="h4">
                    Forgot Password
                  </Typography>

                  <Box component="form"  onSubmit={handleForgotPasswordFormSubmit} sx={{ mt: 3, color:theme.BODY_FONT }}>
                 
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          type="email"
                          value={forgotPasswordFormValues.email}
                          onChange={handleForgotPasswordFormChange} 
                        />
                     
                    <SubmitButton
                      fullWidth
                      loading={apiLoading}
                      variant="contained"
                      buttonText={"Submit"}
                      sx={{my:2}}
                      
                    />
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button onClick={() => handleState({signIn:true}) }  variant="body2">
                          Go back to Sign in
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>

                </Box>
              </Container>

            </ThemeProvider>
          </Box>
        </Box>
      </Box>
      
      
    </>
  ) 
}
